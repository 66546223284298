import { AxiosError } from 'axios';

import FetchError from 'models/FetchError';

export function treatFetchError(error: AxiosError): FetchError {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx

    return {
      code: error.response.status,
      data: error,
      errorCode: (error.response.data || {}).code,
      message: (error.response.data || {}).message,
    };
  }
  if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return { code: 0, data: error };
  }
  // Something happened in setting up the request that triggered an Error
  return { code: -1, data: error };
}

export default {};
