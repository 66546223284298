import { ActionType } from 'typesafe-actions';
import { call, put } from 'redux-saga/effects';

import * as types from 'actions/library';
import { deleteFile, fetchLibrary, uploadFile } from 'api/library';
import { treatFetchError } from 'api/utils';
import LibraryFile from 'models/LibraryFile';

export function* fetchLibrarySaga(): any {
  try {
    const files = yield call(fetchLibrary);

    yield put(types.fetchLibrary.success(files));
  } catch (error: any) {
    yield put(types.fetchLibrary.failure({ error: treatFetchError(error) }));
  }
}

export function* uploadFileSaga(
  action: ActionType<typeof types.uploadFile.request>
) {
  try {
    const data: LibraryFile = yield call(uploadFile, action.payload);

    yield put(types.uploadFile.success(data));
  } catch (error: any) {
    yield put(types.uploadFile.failure({ error: treatFetchError(error) }));
  }
}

export function* deleteFileSaga(
  action: ActionType<typeof types.deleteFile.request>
) {
  const { id } = action.payload;

  try {
    yield call(deleteFile, id);

    yield put(types.deleteFile.success({ id }));
  } catch (error: any) {
    yield put(types.deleteFile.failure({ error: treatFetchError(error) }));
  }
}
