import { ActionType, getType } from 'typesafe-actions';

import * as siteSubscriptions from 'actions/siteSubscriptions';
import FetchError from 'models/FetchError';

type SiteSubscriptionsAction = ActionType<typeof siteSubscriptions>;

export interface ISiteSubscriptionsState {
  readonly error?: FetchError;
  readonly loading: boolean;
}

const initialState: ISiteSubscriptionsState = {
  loading: false,
};

export default (
  state: ISiteSubscriptionsState = initialState,
  action: SiteSubscriptionsAction
): ISiteSubscriptionsState => {
  switch (action.type) {
    case getType(siteSubscriptions.subscribeNewsletter.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(siteSubscriptions.subscribeNewsletter.success): {
      return {
        ...state,
        loading: false,
      };
    }

    case getType(siteSubscriptions.subscribeNewsletter.failure): {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    default:
      return { ...state };
  }
};
