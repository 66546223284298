import { ActionType } from 'typesafe-actions';
import { call, put } from 'redux-saga/effects';

import * as types from 'actions/calculator';
import { fetchCalculatorData, updateCalculatorData } from 'api/calculator';
import { treatFetchError } from 'api/utils';
import CalculatorData from 'models/CalculatorData';

export function* fetchCalculatorDataSaga(): any {
  try {
    const calculatorData = yield call(fetchCalculatorData);

    yield put(types.fetchCalculatorData.success(calculatorData));
  } catch (error: any) {
    yield put(
      types.fetchCalculatorData.failure({ error: treatFetchError(error) })
    );
  }
}
export function* updateCalculatorDataSaga(
  action: ActionType<typeof types.updateCalculatorData.request>
): any {
  try {
    const data: CalculatorData = yield call(
      updateCalculatorData,
      action.payload
    );

    yield put(types.updateCalculatorData.success(data));
  } catch (error: any) {
    yield put(
      types.updateCalculatorData.failure({ error: treatFetchError(error) })
    );
  }
}

export default {};
