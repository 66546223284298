import { createAsyncAction } from 'typesafe-actions';

import { Languages } from 'models/data';
import Media from 'models/Media';
import { IFailed } from './types';

enum Types {
  FETCH_SITEMEDIA = '@@site-media/FETCH_SITEMEDIA',
  FETCH_SITEMEDIA_FAILED = '@@site-media/FETCH_SITEMEDIA_FAILED',
  FETCH_SITEMEDIA_SUCCEEDED = '@@site-media/FETCH_SITEMEDIA_SUCCEEDED',
}

export interface IFetchSiteMedia {
  lang: keyof typeof Languages;
}

export const fetchSiteMedia = createAsyncAction(
  Types.FETCH_SITEMEDIA,
  Types.FETCH_SITEMEDIA_SUCCEEDED,
  Types.FETCH_SITEMEDIA_FAILED
)<IFetchSiteMedia, Media[], IFailed>();
