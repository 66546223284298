import { createAction, createAsyncAction } from 'typesafe-actions';

import Document, { Documents } from 'models/Document';
import { Languages } from 'models/data';
import { IFailed } from './types';

enum Types {
  FETCH_DOCUMENTS = '@@documents/FETCH_DOCUMENTS',
  FETCH_DOCUMENTS_FAILED = '@@documents/FETCH_DOCUMENTS_FAILED',
  FETCH_DOCUMENTS_SUCCEEDED = '@@documents/FETCH_DOCUMENTS_SUCCEEDED',

  FETCH_DOCUMENT = '@@documents/FETCH_DOCUMENT',
  FETCH_DOCUMENT_FAILED = '@@documents/FETCH_DOCUMENT_FAILED',
  FETCH_DOCUMENT_SUCCEEDED = '@@documents/FETCH_DOCUMENT_SUCCEEDED',

  CREATE_DOCUMENT = '@@documents/CREATE_DOCUMENT',
  CREATE_DOCUMENT_FAILED = '@@documents/CREATE_DOCUMENT_FAILED',
  CREATE_DOCUMENT_SUCCEEDED = '@@documents/CREATE_DOCUMENT_SUCCEEDED',

  UPDATE_DOCUMENT = '@@documents/UPDATE_DOCUMENT',
  UPDATE_DOCUMENT_FAILED = '@@documents/UPDATE_DOCUMENT_FAILED',
  UPDATE_DOCUMENT_SUCCEEDED = '@@documents/UPDATE_DOCUMENT_SUCCEEDED',

  ACTIVATE_DOCUMENT = '@@documents/ACTIVATE_DOCUMENT',
  ACTIVATE_DOCUMENT_FAILED = '@@documents/ACTIVATE_DOCUMENT_FAILED',
  ACTIVATE_DOCUMENT_SUCCEEDED = '@@documents/ACTIVATE_DOCUMENT_SUCCEEDED',

  DEACTIVATE_DOCUMENT = '@@documents/DEACTIVATE_DOCUMENT',
  DEACTIVATE_DOCUMENT_FAILED = '@@documents/DEACTIVATE_DOCUMENT_FAILED',
  DEACTIVATE_DOCUMENT_SUCCEEDED = '@@documents/DEACTIVATE_DOCUMENT_SUCCEEDED',

  DELETE_DOCUMENT = '@@documents/DELETE_DOCUMENT',
  DELETE_DOCUMENT_FAILED = '@@documents/DELETE_DOCUMENT_FAILED',
  DELETE_DOCUMENT_SUCCEEDED = '@@documents/DELETE_DOCUMENT_SUCCEEDED',

  RESET_FORM = '@@documents/RESET_FORM',
}

export interface ICreateDocument {
  language: keyof typeof Languages;
  type: 'casestudy' | 'brochure' | 'datasheet';
  title: string;
  description: string | null;
  documentId: string;
}

export interface IUpdateDocument extends ICreateDocument {
  id: string;
}

interface IAction {
  id: string;
}

export const fetchDocuments = createAsyncAction(
  Types.FETCH_DOCUMENTS,
  Types.FETCH_DOCUMENTS_SUCCEEDED,
  Types.FETCH_DOCUMENTS_FAILED
)<void, Documents, IFailed>();

export const fetchDocument = createAsyncAction(
  Types.FETCH_DOCUMENT,
  Types.FETCH_DOCUMENT_SUCCEEDED,
  Types.FETCH_DOCUMENT_FAILED
)<string, Document, IFailed>();

export const createDocument = createAsyncAction(
  Types.CREATE_DOCUMENT,
  Types.CREATE_DOCUMENT_SUCCEEDED,
  Types.CREATE_DOCUMENT_FAILED
)<ICreateDocument, Document, IFailed>();

export const updateDocument = createAsyncAction(
  Types.UPDATE_DOCUMENT,
  Types.UPDATE_DOCUMENT_SUCCEEDED,
  Types.UPDATE_DOCUMENT_FAILED
)<IUpdateDocument, Document, IFailed>();

export const activateDocument = createAsyncAction(
  Types.ACTIVATE_DOCUMENT,
  Types.ACTIVATE_DOCUMENT_SUCCEEDED,
  Types.ACTIVATE_DOCUMENT_FAILED
)<IAction, IAction, IFailed>();

export const deactivateDocument = createAsyncAction(
  Types.DEACTIVATE_DOCUMENT,
  Types.DEACTIVATE_DOCUMENT_SUCCEEDED,
  Types.DEACTIVATE_DOCUMENT_FAILED
)<IAction, IAction, IFailed>();

export const deleteDocument = createAsyncAction(
  Types.DELETE_DOCUMENT,
  Types.DELETE_DOCUMENT_SUCCEEDED,
  Types.DELETE_DOCUMENT_FAILED
)<IAction, IAction, IFailed>();

export const resetDocumentForm = createAction(
  Types.RESET_FORM,
  (action) => () => action()
)();
