import Document from 'models/Document';
import { Languages } from 'models/data';
import axios from './axios';

export async function fetchSiteDocuments(
  language: keyof typeof Languages
): Promise<Document[]> {
  const { data } = await axios.get(`/documents/site/${language}`);

  return data;
}

export default {};
