import { ActionType } from 'typesafe-actions';
import { call, put } from 'redux-saga/effects';

import * as types from 'actions/media';
import {
  createMedia,
  fetchMedia,
  fetchMediaEntry,
  updateMedia,
  activateMedia,
  deactivateMedia,
  deleteMedia,
} from 'api/media';
import { treatFetchError } from 'api/utils';
import Media from 'models/Media';

export function* fetchMediaSaga(): any {
  try {
    const media = yield call(fetchMedia);

    yield put(types.fetchMedia.success(media));
  } catch (error: any) {
    yield put(types.fetchMedia.failure({ error: treatFetchError(error) }));
  }
}

export function* fetchMediaEntrySaga(
  action: ActionType<typeof types.fetchMediaEntry.request>
) {
  try {
    const data: Media = yield call(fetchMediaEntry, action.payload);

    yield put(types.fetchMediaEntry.success(data));
  } catch (error: any) {
    yield put(types.fetchMediaEntry.failure({ error: treatFetchError(error) }));
  }
}

export function* createMediaSaga(
  action: ActionType<typeof types.createMedia.request>
) {
  const { language, title, description, mediaType, mediaURL } = action.payload;

  try {
    const data: Media = yield call(
      createMedia,
      language,
      title,
      mediaType,
      mediaURL,
      description
    );

    yield put(types.createMedia.success(data));
  } catch (error: any) {
    yield put(types.createMedia.failure({ error: treatFetchError(error) }));
  }
}

export function* updateMediaSaga(
  action: ActionType<typeof types.updateMedia.request>
) {
  const { id, language, title, description, mediaType, mediaURL } =
    action.payload;

  try {
    const data: Media = yield call(
      updateMedia,
      id,
      language,
      title,
      mediaType,
      mediaURL,
      description
    );

    yield put(types.updateMedia.success(data));
  } catch (error: any) {
    yield put(types.updateMedia.failure({ error: treatFetchError(error) }));
  }
}

export function* activateMediaSaga(
  action: ActionType<typeof types.activateMedia.request>
) {
  const { id } = action.payload;

  try {
    yield call(activateMedia, id);

    yield put(types.activateMedia.success({ id }));
  } catch (error: any) {
    yield put(types.activateMedia.failure({ error: treatFetchError(error) }));
  }
}

export function* deactivateMediaSaga(
  action: ActionType<typeof types.deactivateMedia.request>
) {
  const { id } = action.payload;

  try {
    yield call(deactivateMedia, id);

    yield put(types.deactivateMedia.success({ id }));
  } catch (error: any) {
    yield put(types.deactivateMedia.failure({ error: treatFetchError(error) }));
  }
}

export function* deleteMediaSaga(
  action: ActionType<typeof types.deleteMedia.request>
) {
  const { id } = action.payload;

  try {
    yield call(deleteMedia, id);

    yield put(types.deleteMedia.success({ id }));
  } catch (error: any) {
    yield put(types.deleteMedia.failure({ error: treatFetchError(error) }));
  }
}
