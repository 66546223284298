import { createAsyncAction } from 'typesafe-actions';

import { Languages } from 'models/data';
import New from 'models/New';
import { IFailed } from './types';

enum Types {
  FETCH_SITENEWS = '@@site-news/FETCH_SITENEWS',
  FETCH_SITENEWS_FAILED = '@@site-news/FETCH_SITENEWS_FAILED',
  FETCH_SITENEWS_SUCCEEDED = '@@site-news/FETCH_SITENEWS_SUCCEEDED',

  FETCH_SITENEWS_BYID = '@@site-news/FETCH_SITENEWS_BYID',
  FETCH_SITENEWS_BYID_FAILED = '@@site-news/FETCH_SITENEWS_BYID_FAILED',
  FETCH_SITENEWS_BYID_SUCCEEDED = '@@site-news/FETCH_SITENEWS_BYID_SUCCEEDED',

  FETCH_SITENEWS_BYPERMALINK = '@@site-news/FETCH_SITENEWS_BYPERMALINK',
  FETCH_SITENEWS_BYPERMALINK_FAILED = '@@site-news/FETCH_SITENEWS_BYPERMALINK_FAILED',
  FETCH_SITENEWS_BYPERMALINK_SUCCEEDED = '@@site-news/FETCH_SITENEWS_BYPERMALINK_SUCCEEDED',

  FETCH_SITENEWS_CATEGORIES = '@@site-news/FETCH_SITENEWS_CATEGORIES',
  FETCH_SITENEWS_CATEGORIES_FAILED = '@@site-news/FETCH_SITENEWS_CATEGORIES_FAILED',
  FETCH_SITENEWS_CATEGORIES_SUCCEEDED = '@@site-news/FETCH_SITENEWS_CATEGORIES_SUCCEEDED',
}

export interface IFetchSiteNews {
  lang: keyof typeof Languages;
  category?: string;
  search?: string;
}

export const fetchSiteNews = createAsyncAction(
  Types.FETCH_SITENEWS,
  Types.FETCH_SITENEWS_SUCCEEDED,
  Types.FETCH_SITENEWS_FAILED
)<IFetchSiteNews, New[], IFailed>();

export const fetchSiteNewsById = createAsyncAction(
  Types.FETCH_SITENEWS_BYID,
  Types.FETCH_SITENEWS_BYID_SUCCEEDED,
  Types.FETCH_SITENEWS_BYID_FAILED
)<string, New, IFailed>();

export const fetchSiteNewsByPermalink = createAsyncAction(
  Types.FETCH_SITENEWS_BYPERMALINK,
  Types.FETCH_SITENEWS_BYPERMALINK_SUCCEEDED,
  Types.FETCH_SITENEWS_BYPERMALINK_FAILED
)<string, New, IFailed>();

export const fetchSiteNewsCategories = createAsyncAction(
  Types.FETCH_SITENEWS_CATEGORIES,
  Types.FETCH_SITENEWS_CATEGORIES_SUCCEEDED,
  Types.FETCH_SITENEWS_CATEGORIES_FAILED
)<keyof typeof Languages, string[], IFailed>();
