import { Languages } from 'models/data';
import New, { News } from 'models/New';
import axios from './axios';

export async function fetchNews(): Promise<News> {
  const { data } = await axios.get('/news');

  const news = {} as Record<string, New>;
  data.forEach((n: New) => {
    news[n.id] = n;
  });

  return news;
}

export async function fetchNew(id: string): Promise<New> {
  const { data } = await axios.get(`/news/id/${id}`);

  return data;
}

export async function createNew(
  language: keyof typeof Languages,
  title: string,
  content: string,
  categories: string[],
  permalink: string | null,
  featuredImageId: string | null
): Promise<New> {
  const { data: news } = await axios.post('/news', {
    language,
    title,
    content,
    categories,
    permalink,
    featuredImageId,
  });

  return news;
}

export async function updateNew(
  id: string,
  language: keyof typeof Languages,
  title: string,
  content: string,
  categories: string[],
  permalink: string | null,
  featuredImageId: string | null
): Promise<New> {
  const { data: news } = await axios.put('/news', {
    id,
    language,
    title,
    content,
    categories,
    permalink,
    featuredImageId,
  });

  return news;
}

export const deleteNew = async (id: string) =>
  axios.delete(`/news/delete/id/${id}`);

export const publishNew = async (id: string) =>
  axios.put('/news/publish', { id });

export const activateNew = async (id: string) =>
  axios.put('/news/activate', { id });

export const deactivateNew = async (id: string) =>
  axios.put('/news/deactivate', { id });

export default {};
