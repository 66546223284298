import { ActionType, getType } from 'typesafe-actions';

import * as library from 'actions/library';
import FetchError from 'models/FetchError';
import LibraryFile from 'models/LibraryFile';

type LibraryAction = ActionType<typeof library>;

export interface ILibraryState {
  readonly files: LibraryFile[];
  readonly callback?: any;
  readonly open: boolean;
  readonly loading: boolean;
  readonly error?: FetchError;
}

const initialState: ILibraryState = {
  open: false,
  loading: false,
  files: [],
};

export default (
  state: ILibraryState = initialState,
  action: LibraryAction
): ILibraryState => {
  switch (action.type) {
    case getType(library.fetchLibrary.request): {
      return {
        ...state,
        files: [],
        error: undefined,
        loading: true,
      };
    }

    case getType(library.fetchLibrary.success): {
      return {
        ...state,
        files: action.payload,
        loading: false,
      };
    }

    case getType(library.fetchLibrary.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(library.uploadFile.request): {
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    }

    case getType(library.uploadFile.success): {
      const { files } = state;

      files.push(action.payload);

      return {
        ...state,
        files,
        loading: false,
      };
    }

    case getType(library.uploadFile.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(library.deleteFile.request): {
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    }

    case getType(library.deleteFile.success): {
      const { files } = state;
      const { id } = action.payload;

      const index = files.findIndex((f) => f.id === id);
      if (index !== -1) {
        files.splice(index, 1);
      }

      return {
        ...state,
        files,
        loading: false,
      };
    }

    case getType(library.deleteFile.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(library.open):
      return {
        ...state,
        callback: action.payload,
        open: true,
      };

    case getType(library.close):
      return {
        ...state,
        open: false,
      };

    default:
      return { ...state };
  }
};
