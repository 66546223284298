import { takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import * as actions from 'actions/index';
import { fetchAccountSaga, logoutSaga } from './authentication';
import {
  fetchNewsSaga,
  fetchNewSaga,
  createNewSaga,
  updateNewSaga,
  deleteNewSaga,
  publishNewSaga,
  activateNewSaga,
  deactivateNewSaga,
} from './news';
import {
  fetchCalculatorDataSaga,
  updateCalculatorDataSaga,
} from './calculator';
import {
  createDocumentSaga,
  fetchDocumentsSaga,
  fetchDocumentSaga,
  updateDocumentSaga,
  activateDocumentSaga,
  deactivateDocumentSaga,
  deleteDocumentSaga,
} from './documents';
import {
  createMediaSaga,
  fetchMediaEntrySaga,
  fetchMediaSaga,
  updateMediaSaga,
  activateMediaSaga,
  deactivateMediaSaga,
  deleteMediaSaga,
} from './media';
import { deleteFileSaga, fetchLibrarySaga, uploadFileSaga } from './library';
import { fetchNewsletterSubscriptionsSaga } from './newsletter';

import { fetchSitePricingCountrySaga, submitDemoRequestSaga } from './site';
import {
  fetchSiteNewsSaga,
  fetchSiteNewsByIdSaga,
  fetchSiteNewsByPermalinkSaga,
  fetchSiteNewsCategoriesSaga,
} from './siteNews';
import { fetchSiteDocumentsSaga } from './siteDocuments';
import { fetchSiteMediaSaga } from './siteMedia';
import { subscribeNewsletterSaga } from './siteSubscriptions';

export default function* saga() {
  yield takeLatest(getType(actions.fetchAccount.request), fetchAccountSaga);
  yield takeLatest(getType(actions.logout.request), logoutSaga);
  yield takeLatest(getType(actions.fetchNews.request), fetchNewsSaga);
  yield takeLatest(getType(actions.fetchNew.request), fetchNewSaga);
  yield takeLatest(getType(actions.createNew.request), createNewSaga);
  yield takeLatest(getType(actions.updateNew.request), updateNewSaga);
  yield takeLatest(getType(actions.deleteNew.request), deleteNewSaga);
  yield takeLatest(getType(actions.publishNew.request), publishNewSaga);
  yield takeLatest(getType(actions.activateNew.request), activateNewSaga);
  yield takeLatest(getType(actions.deactivateNew.request), deactivateNewSaga);
  yield takeLatest(getType(actions.fetchDocuments.request), fetchDocumentsSaga);
  yield takeLatest(getType(actions.fetchDocument.request), fetchDocumentSaga);
  yield takeLatest(
    getType(actions.fetchCalculatorData.request),
    fetchCalculatorDataSaga
  );
  yield takeLatest(
    getType(actions.updateCalculatorData.request),
    updateCalculatorDataSaga
  );
  yield takeLatest(getType(actions.createDocument.request), createDocumentSaga);
  yield takeLatest(getType(actions.updateDocument.request), updateDocumentSaga);
  yield takeLatest(
    getType(actions.activateDocument.request),
    activateDocumentSaga
  );
  yield takeLatest(
    getType(actions.deactivateDocument.request),
    deactivateDocumentSaga
  );
  yield takeLatest(getType(actions.deleteDocument.request), deleteDocumentSaga);
  yield takeLatest(getType(actions.fetchLibrary.request), fetchLibrarySaga);
  yield takeLatest(getType(actions.fetchMedia.request), fetchMediaSaga);
  yield takeLatest(
    getType(actions.fetchMediaEntry.request),
    fetchMediaEntrySaga
  );
  yield takeLatest(getType(actions.createMedia.request), createMediaSaga);
  yield takeLatest(getType(actions.updateMedia.request), updateMediaSaga);
  yield takeLatest(getType(actions.activateMedia.request), activateMediaSaga);
  yield takeLatest(
    getType(actions.deactivateMedia.request),
    deactivateMediaSaga
  );
  yield takeLatest(getType(actions.deleteMedia.request), deleteMediaSaga);
  yield takeLatest(getType(actions.fetchLibrary.request), fetchLibrarySaga);
  yield takeLatest(getType(actions.uploadFile.request), uploadFileSaga);
  yield takeLatest(getType(actions.deleteFile.request), deleteFileSaga);
  yield takeLatest(
    getType(actions.fetchNewsletterSubscriptions.request),
    fetchNewsletterSubscriptionsSaga
  );

  yield takeLatest(
    getType(actions.fetchSitePricingCountry.request),
    fetchSitePricingCountrySaga
  );
  yield takeLatest(
    getType(actions.submitDemoRequest.request),
    submitDemoRequestSaga
  );
  yield takeLatest(
    getType(actions.fetchSiteDocuments.request),
    fetchSiteDocumentsSaga
  );
  yield takeLatest(getType(actions.fetchSiteMedia.request), fetchSiteMediaSaga);
  yield takeLatest(getType(actions.fetchSiteNews.request), fetchSiteNewsSaga);
  yield takeLatest(
    getType(actions.fetchSiteNewsById.request),
    fetchSiteNewsByIdSaga
  );
  yield takeLatest(
    getType(actions.fetchSiteNewsByPermalink.request),
    fetchSiteNewsByPermalinkSaga
  );
  yield takeLatest(
    getType(actions.fetchSiteNewsCategories.request),
    fetchSiteNewsCategoriesSaga
  );
  yield takeLatest(
    getType(actions.subscribeNewsletter.request),
    subscribeNewsletterSaga
  );
}
