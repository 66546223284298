import { ActionType, getType } from 'typesafe-actions';

import * as site from 'actions/site';
import FetchError from 'models/FetchError';

type SiteAction = ActionType<typeof site>;

interface IPricingState {
  data?: string;
  error?: FetchError;
  loading: boolean;
}
interface IDemoState {
  error?: FetchError;
  loading: boolean;
}
export interface ISiteState {
  readonly pricing: IPricingState;
  readonly demo: IDemoState;
}

const initialState: ISiteState = {
  pricing: {
    loading: false,
  },
  demo: {
    loading: false,
  },
};

export default (
  state: ISiteState = initialState,
  action: SiteAction
): ISiteState => {
  switch (action.type) {
    case getType(site.fetchSitePricingCountry.request):
      return {
        ...state,
        pricing: {
          error: undefined,
          loading: true,
        },
      };

    case getType(site.fetchSitePricingCountry.success): {
      return {
        ...state,
        pricing: {
          data: action.payload,
          loading: false,
        },
      };
    }

    case getType(site.fetchSitePricingCountry.failure): {
      const { error } = action.payload;

      return {
        ...state,
        pricing: {
          loading: false,
          error,
        },
      };
    }

    case getType(site.submitDemoRequest.request):
      return {
        ...state,
        demo: {
          error: undefined,
          loading: true,
        },
      };

    case getType(site.submitDemoRequest.success): {
      return {
        ...state,
        demo: { loading: false },
      };
    }

    case getType(site.submitDemoRequest.failure): {
      const { error } = action.payload;

      return {
        ...state,
        demo: {
          loading: false,
          error,
        },
      };
    }

    default:
      return { ...state };
  }
};
