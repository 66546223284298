import { call, put } from 'redux-saga/effects';

import * as types from 'actions/newsletter';
import { fetchNewsletterSubscriptions } from 'api/newsletter';
import { treatFetchError } from 'api/utils';

export function* fetchNewsletterSubscriptionsSaga(): any {
  try {
    const data = yield call(fetchNewsletterSubscriptions);

    yield put(types.fetchNewsletterSubscriptions.success(data));
  } catch (error: any) {
    yield put(
      types.fetchNewsletterSubscriptions.failure({
        error: treatFetchError(error),
      })
    );
  }
}

export default {};
