import { ActionType, getType } from 'typesafe-actions';

import * as siteDocuments from 'actions/siteDocuments';
import Document from 'models/Document';
import FetchError from 'models/FetchError';

type SiteDocumentsAction = ActionType<typeof siteDocuments>;

export interface ISiteDocumentState {
  readonly documents?: Document[];
  readonly error?: FetchError;
  readonly loading: boolean;
}

const initialState: ISiteDocumentState = {
  loading: false,
};

export default (
  state: ISiteDocumentState = initialState,
  action: SiteDocumentsAction
): ISiteDocumentState => {
  switch (action.type) {
    case getType(siteDocuments.fetchSiteDocuments.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(siteDocuments.fetchSiteDocuments.success): {
      return {
        ...state,
        documents: action.payload,
        loading: false,
      };
    }

    case getType(siteDocuments.fetchSiteDocuments.failure): {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    default:
      return { ...state };
  }
};
