import { ActionType, getType } from 'typesafe-actions';

import * as documents from 'actions/documents';
import Document, { Documents } from 'models/Document';
import FetchError from 'models/FetchError';

type DocumentsAction = ActionType<typeof documents>;

interface IForm {
  redirectReady: boolean;
  data?: Document;
  error?: FetchError;
  loading: boolean;
}

export interface IDocumentsState {
  readonly form: IForm;
  readonly documents?: Documents;
  readonly error?: FetchError;
  readonly loading: boolean;
}

const initialState: IDocumentsState = {
  loading: false,
  form: {
    redirectReady: false,
    loading: false,
  },
};

export default (
  state: IDocumentsState = initialState,
  action: DocumentsAction
): IDocumentsState => {
  switch (action.type) {
    case getType(documents.fetchDocuments.request):
      return {
        ...state,
        loading: true,
      };

    case getType(documents.fetchDocuments.success): {
      return {
        ...state,
        documents: action.payload,
        error: undefined,
        loading: false,
      };
    }

    case getType(documents.fetchDocuments.failure): {
      const { error } = action.payload;

      return {
        ...state,
        documents: undefined,
        error,
        loading: false,
      };
    }

    case getType(documents.fetchDocument.request):
      return {
        ...state,
        form: {
          ...state.form,
          data: undefined,
          error: undefined,
          loading: true,
        },
      };

    case getType(documents.fetchDocument.success): {
      return {
        ...state,
        form: {
          ...state.form,
          data: action.payload,
          loading: false,
        },
        error: undefined,
        loading: false,
      };
    }

    case getType(documents.fetchDocument.failure): {
      const { error } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          data: undefined,
          loading: false,
          error,
        },
        error,
        loading: false,
      };
    }

    case getType(documents.createDocument.request):
      return {
        ...state,
        form: {
          ...state.form,
          redirectReady: false,
          data: undefined,
          error: undefined,
          loading: true,
        },
        loading: true,
      };

    case getType(documents.createDocument.success): {
      const { id } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          redirectReady: true,
          data: {
            ...state.form.data,
            ...action.payload,
          },
          loading: false,
        },
        documents: {
          ...state.documents,
          [id]: action.payload,
        },
        error: undefined,
        loading: false,
      };
    }

    case getType(documents.createDocument.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        form: {
          ...state.form,
          redirectReady: false,
          loading: false,
          error,
        },
        loading: false,
      };
    }

    case getType(documents.updateDocument.request):
      return {
        ...state,
        form: {
          ...state.form,
          redirectReady: false,
          error: undefined,
          loading: true,
        },
        loading: true,
      };

    case getType(documents.updateDocument.success): {
      const { id } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          redirectReady: true,
          data: {
            ...state.form.data,
            ...action.payload,
          },
          loading: false,
        },
        documents: {
          ...state.documents,
          [id]: action.payload,
        },
        error: undefined,
        loading: false,
      };
    }

    case getType(documents.updateDocument.failure): {
      const { error } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          redirectReady: false,
          error,
          loading: false,
        },
        error,
        loading: false,
      };
    }

    case getType(documents.activateDocument.request): {
      return {
        ...state,
        loading: true,
      };
    }

    case getType(documents.activateDocument.success): {
      const { id } = action.payload;

      return {
        ...state,
        documents: {
          ...state.documents,
          [id]: {
            ...((state.documents || {})[id] || {}),
            active: true,
          },
        },
        error: undefined,
        loading: false,
      };
    }

    case getType(documents.activateDocument.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(documents.deactivateDocument.request): {
      return {
        ...state,
        loading: true,
      };
    }

    case getType(documents.deactivateDocument.success): {
      const { id } = action.payload;

      return {
        ...state,
        documents: {
          ...state.documents,
          [id]: {
            ...((state.documents || {})[id] || {}),
            active: false,
          },
        },
        error: undefined,
        loading: false,
      };
    }

    case getType(documents.deactivateDocument.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(documents.deleteDocument.request): {
      return {
        ...state,
        loading: true,
      };
    }

    case getType(documents.deleteDocument.success): {
      const { id } = action.payload;

      const n = state.documents;

      delete (n || {})[id];

      return {
        ...state,
        documents: n,
        error: undefined,
        loading: false,
      };
    }

    case getType(documents.deleteDocument.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(documents.resetDocumentForm):
      return {
        ...state,
        form: {
          redirectReady: false,
          loading: false,
        },
      };

    default:
      return { ...state };
  }
};
