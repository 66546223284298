import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import deLocale from 'date-fns/locale/de';
import esLocale from 'date-fns/locale/es';
import enLocale from 'date-fns/locale/en-GB';
import ptLocale from 'date-fns/locale/pt';
import frLocale from 'date-fns/locale/fr';
import roLocale from 'date-fns/locale/ro';
import React, { FunctionComponent } from 'react';

interface IProps {
  language: string;
  children: any;
}

const PickerLoading: FunctionComponent<IProps> = ({
  children,
  language,
}: IProps) => {
  let localeTools = enLocale;
  if (language === 'de') {
    localeTools = deLocale;
  } else if (language === 'es') {
    localeTools = esLocale;
  } else if (language === 'fr') {
    localeTools = frLocale;
  } else if (language === 'pt') {
    localeTools = ptLocale;
  } else if (language === 'ro') {
    localeTools = roLocale;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeTools}>
      {children}
    </LocalizationProvider>
  );
};

export default PickerLoading;
