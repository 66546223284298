import { call, put } from 'redux-saga/effects';

import { ActionType } from 'typesafe-actions';

import * as types from 'actions/siteMedia';
import { fetchSiteMedia } from 'api/siteMedia';
import { treatFetchError } from 'api/utils';

export function* fetchSiteMediaSaga(
  action: ActionType<typeof types.fetchSiteMedia.request>
): any {
  const { lang } = action.payload;

  try {
    const media = yield call(fetchSiteMedia, lang);

    yield put(types.fetchSiteMedia.success(media));
  } catch (error: any) {
    yield put(types.fetchSiteMedia.failure({ error: treatFetchError(error) }));
  }
}

export default {};
