import { createAsyncAction } from 'typesafe-actions';

import { NewsletterSubscriptions } from 'models/NewsletterSubscription';
import { IFailed } from './types';

enum Types {
  FETCH_NEWSLETTER_SUBSCRIPTIONS = '@@newsletter/FETCH_NEWSLETTER_SUBSCRIPTIONS',
  FETCH_NEWSLETTER_SUBSCRIPTIONS_FAILED = '@@newsletter/FETCH_NEWSLETTER_SUBSCRIPTIONS_FAILED',
  FETCH_NEWSLETTER_SUBSCRIPTIONS_SUCCEEDED = '@@newsletter/FETCH_NEWSLETTER_SUBSCRIPTIONS_SUCCEEDED',
}

export const fetchNewsletterSubscriptions = createAsyncAction(
  Types.FETCH_NEWSLETTER_SUBSCRIPTIONS,
  Types.FETCH_NEWSLETTER_SUBSCRIPTIONS_SUCCEEDED,
  Types.FETCH_NEWSLETTER_SUBSCRIPTIONS_FAILED
)<void, NewsletterSubscriptions, IFailed>();

export default {};
