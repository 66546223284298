import React, { FunctionComponent } from 'react';

interface IProps {
  loading: boolean;
  children?: any;
}

const LocalLoading: FunctionComponent<IProps> = ({
  children,
  loading,
}: IProps) => {
  return loading ? (
    <div className="local-loading">
      <img src="/loading.gif" alt="..." />
    </div>
  ) : (
    <>{children}</>
  );
};

// @ts-ignore
LocalLoading.defaultProps = {
  children: undefined,
};

export default LocalLoading;
