import { createAction, createAsyncAction } from 'typesafe-actions';

import LibraryFile from 'models/LibraryFile';
import { IFailed } from './types';

enum Types {
  FETCH_LIBRARY = '@@library/FETCH_LIBRARY',
  FETCH_LIBRARY_FAILED = '@@library/FETCH_LIBRARY_FAILED',
  FETCH_LIBRARY_SUCCEEDED = '@@library/FETCH_LIBRARY_SUCCEEDED',

  UPLOAD_FILE = '@@library/UPLOAD_FILE',
  UPLOAD_FILE_FAILED = '@@library/UPLOAD_FILE_FAILED',
  UPLOAD_FILE_SUCCEEDED = '@@library/UPLOAD_FILE_SUCCEEDED',

  DELETE_FILE = '@@library/DELETE_FILE',
  DELETE_FILE_FAILED = '@@library/DELETE_FILE_FAILED',
  DELETE_FILE_SUCCEEDED = '@@library/DELETE_FILE_SUCCEEDED',

  OPEM = '@library/OPEM',
  CLOSE = '@library/CLOSE',
}

interface IAction {
  id: string;
}

export const fetchLibrary = createAsyncAction(
  Types.FETCH_LIBRARY,
  Types.FETCH_LIBRARY_SUCCEEDED,
  Types.FETCH_LIBRARY_FAILED
)<void, LibraryFile[], IFailed>();

export const uploadFile = createAsyncAction(
  Types.UPLOAD_FILE,
  Types.UPLOAD_FILE_SUCCEEDED,
  Types.UPLOAD_FILE_FAILED
)<File, LibraryFile, IFailed>();

export const deleteFile = createAsyncAction(
  Types.DELETE_FILE,
  Types.DELETE_FILE_SUCCEEDED,
  Types.DELETE_FILE_FAILED
)<IAction, IAction, IFailed>();

export const open = createAction(
  Types.OPEM,
  (action) => (callback?: any) => action(callback)
)();
export const close = createAction(Types.CLOSE, (action) => () => action())();
