import React, { FunctionComponent } from 'react';
import { IntlProvider } from 'react-intl';

import deTranslation from '../../translations/de.json';
import enTranslation from '../../translations/en.json';
import esTranslation from '../../translations/es.json';
import frTranslation from '../../translations/fr.json';
import itTranslation from '../../translations/it.json';
import ptTranslation from '../../translations/pt.json';

interface IntlProps {
  lang: string;
  children: any;
}

const Intl: FunctionComponent<IntlProps> = ({ children, lang }: IntlProps) => {
  let messages = enTranslation;
  let locale = 'en-GB';

  if (lang === 'de') {
    messages = deTranslation;
    locale = 'de-DE';
  } else if (lang === 'es') {
    messages = esTranslation;
    locale = 'es-ES';
  } else if (lang === 'fr') {
    messages = frTranslation;
    locale = 'fr-FR';
  } else if (lang === 'it') {
    messages = itTranslation;
    locale = 'it-IT';
  } else if (lang === 'pt') {
    messages = ptTranslation;
    locale = 'pt-PT';
  }

  return (
    <IntlProvider
      key={locale}
      locale={locale}
      textComponent="span"
      messages={messages}
      defaultLocale="en-GB"
    >
      {children}
    </IntlProvider>
  );
};

export default Intl;
