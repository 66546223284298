import axios from './axios';

export async function fetchSitePricingCountry(): Promise<string> {
  const { data } = await axios.get('/service/ipinfo');

  return data;
}

export async function submitDemoRequest(
  name: string,
  email: string,
  phone: string,
  country: string,
  company: string,
  jobtitle: string,
  industry: string,
  subsector: string | undefined,
  usecase: string,
  comments: string | undefined
): Promise<void> {
  await axios.post('/service/requestdemo', {
    name,
    email,
    phone,
    country,
    company,
    jobtitle,
    industry,
    subsector,
    usecase,
    comments,
  });
}

export default {};
