import Document, { Documents } from 'models/Document';
import { Languages } from 'models/data';
import axios from './axios';

export async function fetchDocuments(): Promise<Documents> {
  const { data } = await axios.get('/documents');

  const documents = {} as Record<string, Document>;
  data.forEach((d: Document) => {
    documents[d.id] = d;
  });

  return documents;
}

export async function fetchDocument(id: string): Promise<Document> {
  const { data } = await axios.get(`/documents/id/${id}`);

  return data;
}

export async function createDocument(
  language: keyof typeof Languages,
  type: 'casestudy' | 'brochure' | 'datasheet',
  title: string,
  documentId: string,
  description: string | null
): Promise<Document> {
  const { data: simulation } = await axios.post('/documents', {
    language,
    type,
    title,
    description,
    documentId,
  });

  return simulation;
}

export async function updateDocument(
  id: string,
  language: keyof typeof Languages,
  type: 'casestudy' | 'brochure' | 'datasheet',
  title: string,
  documentId: string,
  description: string | null
): Promise<Document> {
  const { data: document } = await axios.put('/documents', {
    id,
    language,
    type,
    title,
    description,
    documentId,
  });

  return document;
}

export const activateDocument = async (id: string) =>
  axios.put('/documents/activate', { id });

export const deactivateDocument = async (id: string) =>
  axios.put('/documents/deactivate', { id });

export const deleteDocument = async (id: string) =>
  axios.delete(`/documents/delete/id/${id}`);

export default {};
