import LibraryFile from 'models/LibraryFile';
import axios from './axios';

export async function fetchLibrary(): Promise<LibraryFile[]> {
  const { data } = await axios.get('/library');

  return data;
}

export async function uploadFile(file: File): Promise<LibraryFile> {
  const data = new FormData();
  data.append('file', file);

  const { data: f } = await axios.post('/library/file', data);

  return f;
}

export const deleteFile = async (id: string) =>
  axios.delete(`/library/file/delete/id/${id}`);

export default {};
