import { call, put } from 'redux-saga/effects';

import { ActionType } from 'typesafe-actions';

import * as types from 'actions/siteNews';
import {
  fetchSiteNews,
  fetchSiteNewsById,
  fetchSiteNewsByPermalink,
  fetchSiteNewsCategories,
} from 'api/siteNews';
import { treatFetchError } from 'api/utils';

export function* fetchSiteNewsSaga(
  action: ActionType<typeof types.fetchSiteNews.request>
): any {
  const { lang, category, search } = action.payload;

  try {
    const news = yield call(fetchSiteNews, lang, category, search);

    yield put(types.fetchSiteNews.success(news));
  } catch (error: any) {
    yield put(types.fetchSiteNews.failure({ error: treatFetchError(error) }));
  }
}

export function* fetchSiteNewsByIdSaga(
  action: ActionType<typeof types.fetchSiteNewsById.request>
): any {
  try {
    const news = yield call(fetchSiteNewsById, action.payload);

    yield put(types.fetchSiteNewsById.success(news));
  } catch (error: any) {
    yield put(
      types.fetchSiteNewsById.failure({ error: treatFetchError(error) })
    );
  }
}

export function* fetchSiteNewsByPermalinkSaga(
  action: ActionType<typeof types.fetchSiteNewsByPermalink.request>
): any {
  try {
    const news = yield call(fetchSiteNewsByPermalink, action.payload);

    yield put(types.fetchSiteNewsByPermalink.success(news));
  } catch (error: any) {
    yield put(
      types.fetchSiteNewsByPermalink.failure({ error: treatFetchError(error) })
    );
  }
}

export function* fetchSiteNewsCategoriesSaga(
  action: ActionType<typeof types.fetchSiteNewsCategories.request>
): any {
  try {
    const news = yield call(fetchSiteNewsCategories, action.payload);

    yield put(types.fetchSiteNewsCategories.success(news));
  } catch (error: any) {
    yield put(
      types.fetchSiteNewsCategories.failure({ error: treatFetchError(error) })
    );
  }
}
