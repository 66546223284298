import NewsletterSubscription, {
  NewsletterSubscriptions,
} from 'models/NewsletterSubscription';
import axios from './axios';

// eslint-disable-next-line prettier/prettier
export async function fetchNewsletterSubscriptions(): Promise<NewsletterSubscriptions> {
  const { data } = await axios.get('/newsletter/subscriptions');

  const subs = {} as Record<string, NewsletterSubscription>;
  data.forEach((n: NewsletterSubscription) => {
    subs[n.id] = n;
  });

  return subs;
}

export default {};
