import { createAsyncAction } from 'typesafe-actions';

import { Languages } from 'models/data';
import Document from 'models/Document';
import { IFailed } from './types';

enum Types {
  FETCH_SITEDOCUMENTS = '@@site-documents/FETCH_SITEDOCUMENTS',
  FETCH_SITEDOCUMENTS_FAILED = '@@site-documents/FETCH_SITEDOCUMENTS_FAILED',
  FETCH_SITEDOCUMENTS_SUCCEEDED = '@@site-documents/FETCH_SITEDOCUMENTS_SUCCEEDED',
}

export interface IFetchSiteDocuments {
  lang: keyof typeof Languages;
}

export const fetchSiteDocuments = createAsyncAction(
  Types.FETCH_SITEDOCUMENTS,
  Types.FETCH_SITEDOCUMENTS_SUCCEEDED,
  Types.FETCH_SITEDOCUMENTS_FAILED
)<IFetchSiteDocuments, Document[], IFailed>();
