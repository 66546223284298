import Media, { Medias } from 'models/Media';
import { Languages } from 'models/data';
import axios from './axios';

export async function fetchMedia(): Promise<Medias> {
  const { data } = await axios.get('/media');

  const medias = {} as Record<string, Media>;
  data.forEach((m: Media) => {
    medias[m.id] = m;
  });

  return medias;
}

export async function fetchMediaEntry(id: string): Promise<Media> {
  const { data } = await axios.get(`/media/id/${id}`);

  return data;
}

export async function createMedia(
  language: keyof typeof Languages,
  title: string,
  mediaType: string,
  mediaURL: string,
  description: string | null
): Promise<Document> {
  const { data: simulation } = await axios.post('/media', {
    language,
    title,
    mediaType,
    mediaURL,
    description,
  });

  return simulation;
}

export async function updateMedia(
  id: string,
  language: keyof typeof Languages,
  title: string,
  mediaType: string,
  mediaURL: string,
  description: string | null
): Promise<Document> {
  const { data: document } = await axios.put('/media', {
    id,
    language,
    title,
    mediaType,
    mediaURL,
    description,
  });

  return document;
}

export const activateMedia = async (id: string) =>
  axios.put('/media/activate', { id });

export const deactivateMedia = async (id: string) =>
  axios.put('/media/deactivate', { id });

export const deleteMedia = async (id: string) =>
  axios.delete(`/media/delete/id/${id}`);

export default {};
