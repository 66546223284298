import 'core-js/stable';
import 'regenerator-runtime/runtime';

import $ from 'jquery';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import App from './components';
import store from './store';
import { register } from './serviceWorker';

import './index.scss';

$(document).on('ready', () => {
  window.onscroll = () => {
    if (!$(window).scrollTop()) {
      $('header').removeClass('fixed');
      $('#header-logo').attr('src', '/logo.png');
    } else if (!$('header').hasClass('fixed')) {
      $('header').addClass('fixed');
      $('#header-logo').attr('src', '/logo_top.png');
    }
  };
});

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

if (process.env.NODE_ENV === 'production') {
  register({
    onUpdate: async (registration: any) => {
      // We want to run this code only if we detect a new service worker is
      // waiting to be activated.
      // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
      if (registration && registration.waiting) {
        await registration.unregister();
        // Makes Workbox call skipWaiting()
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        // Once the service worker is unregistered, we can reload the page to let
        // the browser download a fresh copy of our app (invalidating the cache)
        window.location.reload();
      }
    },
  });
}

export default {};
