import { call, put } from 'redux-saga/effects';

import { ActionType } from 'typesafe-actions';

import * as types from 'actions/siteSubscriptions';
import { subscribeNewsletter } from 'api/siteSubscriptions';
import { treatFetchError } from 'api/utils';

export function* subscribeNewsletterSaga(
  action: ActionType<typeof types.subscribeNewsletter.request>
) {
  const { email, intl } = action.payload;

  try {
    yield call(subscribeNewsletter, email);

    yield put(types.subscribeNewsletter.success({ intl }));
  } catch (error: any) {
    yield put(
      types.subscribeNewsletter.failure({ error: treatFetchError(error), intl })
    );
  }
}

export default {};
