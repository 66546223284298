import { ActionType, getType } from 'typesafe-actions';

import * as newsletter from 'actions/newsletter';
import { NewsletterSubscriptions } from 'models/NewsletterSubscription';
import FetchError from 'models/FetchError';

type NewsletterAction = ActionType<typeof newsletter>;

export interface INewsletterState {
  readonly subscriptions?: NewsletterSubscriptions;
  readonly error?: FetchError;
  readonly loading: boolean;
}

const initialState: INewsletterState = {
  loading: false,
};

export default (
  state: INewsletterState = initialState,
  action: NewsletterAction
): INewsletterState => {
  switch (action.type) {
    case getType(newsletter.fetchNewsletterSubscriptions.request):
      return {
        ...state,
        loading: true,
      };

    case getType(newsletter.fetchNewsletterSubscriptions.success): {
      return {
        ...state,
        subscriptions: action.payload,
        error: undefined,
        loading: false,
      };
    }

    case getType(newsletter.fetchNewsletterSubscriptions.failure): {
      const { error } = action.payload;

      return {
        ...state,
        subscriptions: undefined,
        error,
        loading: false,
      };
    }

    default:
      return { ...state };
  }
};
