import { createAsyncAction } from 'typesafe-actions';

import CalculatorData from 'models/CalculatorData';
import { IFailed } from './types';

enum Types {
  FETCH_CALCULATOR_DATA = '@@calculator/FETCH_CALCULATOR_DATA',
  FETCH_CALCULATOR_DATA_FAILED = '@@calculator/FETCH_CALCULATOR_DATA_FAILED',
  FETCH_CALCULATOR_DATA_SUCCEEDED = '@@calculator/FETCH_CALCULATOR_DATA_SUCCEEDED',

  UPDATE_CALCULATOR_DATA = '@@calculator/UPDATE_CALCULATOR_DATA',
  UPDATE_CALCULATOR_DATA_SUCCEEDED = '@@calculator/UPDATE_CALCULATOR_DATA_SUCCEEDED',
  UPDATE_CALCULATOR_DATA_FAILED = '@@calculator/UPDATE_CALCULATOR_DATA_FAILED',
}

export const fetchCalculatorData = createAsyncAction(
  Types.FETCH_CALCULATOR_DATA,
  Types.FETCH_CALCULATOR_DATA_SUCCEEDED,
  Types.FETCH_CALCULATOR_DATA_FAILED
)<string, CalculatorData, IFailed>();

export const updateCalculatorData = createAsyncAction(
  Types.UPDATE_CALCULATOR_DATA,
  Types.UPDATE_CALCULATOR_DATA_SUCCEEDED,
  Types.UPDATE_CALCULATOR_DATA_FAILED
)<CalculatorData, CalculatorData, IFailed>();
