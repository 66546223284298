import { call, put } from 'redux-saga/effects';

import * as types from 'actions/authentication';
import { fetchAccount, logout } from 'api/authentication';
import { treatFetchError } from 'api/utils';

export function* fetchAccountSaga(): any {
  try {
    const account = yield call(fetchAccount);

    yield put(types.fetchAccount.success(account));
  } catch (error: any) {
    yield put(types.fetchAccount.failure({ error: treatFetchError(error) }));
  }
}

export function* logoutSaga(): any {
  try {
    const response = yield call(logout);

    if (response.status === 204) {
      yield put(types.logout.success());
    } else {
      yield put(types.logout.failure(response.data.message));
    }
  } catch (error: any) {
    yield put(types.logout.failure({ error: treatFetchError(error) }));
  }
}
