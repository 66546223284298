import { ActionType } from 'typesafe-actions';
import { call, put } from 'redux-saga/effects';

import * as types from 'actions/documents';
import {
  createDocument,
  fetchDocument,
  fetchDocuments,
  updateDocument,
  activateDocument,
  deactivateDocument,
  deleteDocument,
} from 'api/documents';
import { treatFetchError } from 'api/utils';
import Document from 'models/Document';

export function* fetchDocumentsSaga(): any {
  try {
    const documents = yield call(fetchDocuments);

    yield put(types.fetchDocuments.success(documents));
  } catch (error: any) {
    yield put(types.fetchDocuments.failure({ error: treatFetchError(error) }));
  }
}

export function* fetchDocumentSaga(
  action: ActionType<typeof types.fetchDocument.request>
) {
  try {
    const data: Document = yield call(fetchDocument, action.payload);

    yield put(types.fetchDocument.success(data));
  } catch (error: any) {
    yield put(types.fetchDocument.failure({ error: treatFetchError(error) }));
  }
}

export function* createDocumentSaga(
  action: ActionType<typeof types.createDocument.request>
) {
  const { language, type, title, description, documentId } = action.payload;

  try {
    const data: Document = yield call(
      createDocument,
      language,
      type,
      title,
      documentId,
      description
    );

    yield put(types.createDocument.success(data));
  } catch (error: any) {
    yield put(types.createDocument.failure({ error: treatFetchError(error) }));
  }
}

export function* updateDocumentSaga(
  action: ActionType<typeof types.updateDocument.request>
) {
  const { id, language, type, title, description, documentId } = action.payload;

  try {
    const data: Document = yield call(
      updateDocument,
      id,
      language,
      type,
      title,
      documentId,
      description
    );

    yield put(types.updateDocument.success(data));
  } catch (error: any) {
    yield put(types.updateDocument.failure({ error: treatFetchError(error) }));
  }
}

export function* activateDocumentSaga(
  action: ActionType<typeof types.activateDocument.request>
) {
  const { id } = action.payload;

  try {
    yield call(activateDocument, id);

    yield put(types.activateDocument.success({ id }));
  } catch (error: any) {
    yield put(
      types.activateDocument.failure({ error: treatFetchError(error) })
    );
  }
}

export function* deactivateDocumentSaga(
  action: ActionType<typeof types.deactivateDocument.request>
) {
  const { id } = action.payload;

  try {
    yield call(deactivateDocument, id);

    yield put(types.deactivateDocument.success({ id }));
  } catch (error: any) {
    yield put(
      types.deactivateDocument.failure({ error: treatFetchError(error) })
    );
  }
}

export function* deleteDocumentSaga(
  action: ActionType<typeof types.deleteDocument.request>
) {
  const { id } = action.payload;

  try {
    yield call(deleteDocument, id);

    yield put(types.deleteDocument.success({ id }));
  } catch (error: any) {
    yield put(types.deleteDocument.failure({ error: treatFetchError(error) }));
  }
}
