import { defineMessages } from 'react-intl';
import { ActionType, getType } from 'typesafe-actions';
import { v4 as uuid } from 'uuid';

import * as type from 'actions/actionFeedback';
import * as site from 'actions/site';
import * as siteSubscriptions from 'actions/siteSubscriptions';
import * as library from 'actions/library';

type FeedbackAction = ActionType<
  typeof type | typeof site | typeof siteSubscriptions | typeof library
>;

export enum IActionFeedbackType {
  ERROR = 'error',
  SUCCESS = 'success',
}

export interface IActionFeedback {
  id: string;
  type: IActionFeedbackType;
  message: string;
  datetime: Date;
}

export interface IActionFeedbackState {
  readonly messages: Record<string, IActionFeedback>;
}

const initialState: IActionFeedbackState = {
  messages: {},
};

export const messages = defineMessages({
  requestDemoSuccessMsg: {
    defaultMessage: 'Demo requested! Thanks, we will get in touch soon.',
    id: 'site.requestdemo.success',
  },
  requestDemoErrorMsg: {
    defaultMessage:
      'Sorry, we were unable to submit your request, please try again or contact us directly.',
    id: 'site.requestdemo.success',
  },
  genericErrorMsg: {
    defaultMessage:
      'Sorry, we were unable to complete this action. Please, try again.',
    id: 'site.error.generic',
  },
  genericSuccessMsg: {
    defaultMessage: 'Subscription successful!',
    id: 'news.subscribesuccess',
  },
});

/**
 * @description Generates a new feedback message for IActionFeedbackState
 * @param {IActionFeedbackState} state IActionFeedbackState
 * @param {IActionFeedbackType} messageType Message type
 * @param {string} msg Message text
 */
const generateMessage = (
  state: IActionFeedbackState,
  messageType: IActionFeedbackType,
  message: string
): IActionFeedbackState => {
  const id: string = uuid();

  return {
    ...state,
    messages: {
      ...state.messages,
      [id]: {
        datetime: new Date(),
        id,
        message,
        type: messageType,
      },
    },
  };
};

export default (
  state: IActionFeedbackState = initialState,
  action: FeedbackAction
): IActionFeedbackState => {
  switch (action.type) {
    case getType(site.submitDemoRequest.success): {
      const { intl } = action.payload;

      return generateMessage(
        state,
        IActionFeedbackType.SUCCESS,
        intl.formatMessage(messages.requestDemoSuccessMsg)
      );
    }

    case getType(site.submitDemoRequest.failure): {
      const { intl } = action.payload;

      return generateMessage(
        state,
        IActionFeedbackType.ERROR,
        intl.formatMessage(messages.requestDemoErrorMsg)
      );
    }

    case getType(siteSubscriptions.subscribeNewsletter.success): {
      const { intl, feedback } = action.payload;

      if (!feedback) {
        return state;
      }

      return generateMessage(
        state,
        IActionFeedbackType.SUCCESS,
        intl.formatMessage(messages.genericSuccessMsg)
      );
    }

    case getType(siteSubscriptions.subscribeNewsletter.failure): {
      const { intl } = action.payload;

      return generateMessage(
        state,
        IActionFeedbackType.ERROR,
        intl.formatMessage(messages.genericErrorMsg)
      );
    }

    case getType(library.deleteFile.failure): {
      return generateMessage(
        state,
        IActionFeedbackType.ERROR,
        'Sorry, we were unable to complete this action. Please, try again.'
      );
    }

    case getType(type.closeNotification): {
      const msgs = state.messages;
      const id = action.payload;
      // @ts-ignore
      delete msgs[id];

      return {
        ...state,
        messages: { ...msgs },
      };
    }

    default:
      return state;
  }
};
