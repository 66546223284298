import green from '@mui/material/colors/green';
import IconButton from '@mui/material/IconButton';
import SnackbarContent from '@mui/material/SnackbarContent';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import React, { PureComponent } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import {
  IActionFeedback,
  IActionFeedbackType,
} from '../../../reducers/actionFeedback';

import './index.scss';

interface IProps {
  closeNotification: (id: string) => void;
  notifications: Record<string, IActionFeedback>;
}

class ActionFeedback extends PureComponent<IProps> {
  constructor(props: IProps) {
    super(props);

    setInterval(() => {
      const { closeNotification, notifications } = this.props;

      Object.values(notifications).forEach((notification) => {
        if (notification.datetime.getTime() < Date.now() - 3000) {
          closeNotification(notification.id);
        }
      });
    }, 3000);
  }

  public handleClose(id: string) {
    const { closeNotification } = this.props;

    closeNotification(id);
  }

  public render() {
    const { notifications } = this.props;

    const message = (notification: IActionFeedback) => (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        {notification.type === IActionFeedbackType.SUCCESS ? (
          <CheckCircleIcon />
        ) : (
          <CancelIcon />
        )}
        <span style={{ paddingLeft: '10px' }}>{notification.message}</span>
      </div>
    );

    return (
      <div
        style={{
          bottom: '30px',
          position: 'fixed',
          right: '20px',
          zIndex: 9999,
        }}
      >
        <TransitionGroup>
          {Object.values(notifications).map((notification) => (
            <CSSTransition
              key={notification.id}
              classNames="fade"
              timeout={500}
            >
              <SnackbarContent
                className="floating-message"
                style={{
                  backgroundColor:
                    notification.type === IActionFeedbackType.SUCCESS
                      ? green[600]
                      : '#d32f2f',
                  marginBottom: '10px',
                }}
                message={message(notification)}
                action={[
                  <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={() => this.handleClose(notification.id)}
                  >
                    <CloseIcon />
                  </IconButton>,
                ]}
              />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    );
  }
}

export default ActionFeedback;
