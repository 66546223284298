import { ActionType, getType } from 'typesafe-actions';

import * as news from 'actions/news';
import New, { News } from 'models/New';
import FetchError from 'models/FetchError';

type AuthenticationAction = ActionType<typeof news>;

interface IForm {
  data?: New;
  error?: FetchError;
  redirectReady: boolean;
  loading: boolean;
}

export interface INewsState {
  readonly form: IForm;
  readonly news?: News;
  readonly error?: FetchError;
  readonly loading: boolean;
}

const initialState: INewsState = {
  loading: false,
  form: {
    redirectReady: false,
    loading: false,
  },
};

export default (
  state: INewsState = initialState,
  action: AuthenticationAction
): INewsState => {
  switch (action.type) {
    case getType(news.fetchNews.request):
      return {
        ...state,
        loading: true,
      };

    case getType(news.fetchNews.success): {
      return {
        ...state,
        news: action.payload,
        error: undefined,
        loading: false,
      };
    }

    case getType(news.fetchNews.failure): {
      const { error } = action.payload;

      return {
        ...state,
        news: undefined,
        error,
        loading: false,
      };
    }

    case getType(news.fetchNew.request):
      return {
        ...state,
        form: {
          ...state.form,
          data: undefined,
          error: undefined,
          loading: true,
        },
      };

    case getType(news.fetchNew.success): {
      return {
        ...state,
        form: {
          ...state.form,
          data: action.payload,
          loading: false,
        },
        error: undefined,
        loading: false,
      };
    }

    case getType(news.fetchNew.failure): {
      const { error } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          data: undefined,
          loading: false,
          error,
        },
      };
    }

    case getType(news.resetNewsForm):
      return {
        ...state,
        form: {
          redirectReady: false,
          loading: false,
        },
      };

    case getType(news.createNew.request):
      return {
        ...state,
        form: {
          ...state.form,
          redirectReady: false,
          data: undefined,
          error: undefined,
          loading: true,
        },
        loading: true,
      };

    case getType(news.createNew.success): {
      const { id } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          redirectReady: true,
          data: {
            ...state.form.data,
            ...action.payload,
          },
          loading: false,
        },
        news: {
          ...state.news,
          [id]: action.payload,
        },
        error: undefined,
        loading: false,
      };
    }

    case getType(news.createNew.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        form: {
          ...state.form,
          redirectReady: false,
          loading: false,
          error,
        },
        loading: false,
      };
    }

    case getType(news.updateNew.request):
      return {
        ...state,
        form: {
          ...state.form,
          redirectReady: false,
          error: undefined,
          loading: true,
        },
        loading: true,
      };

    case getType(news.updateNew.success): {
      const { id } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          redirectReady: true,
          data: {
            ...state.form.data,
            ...action.payload,
          },
          loading: false,
        },
        news: {
          ...state.news,
          [id]: action.payload,
        },
        error: undefined,
        loading: false,
      };
    }

    case getType(news.updateNew.failure): {
      const { error } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          redirectReady: false,
          error,
          loading: false,
        },
        error,
        loading: false,
      };
    }

    case getType(news.deleteNew.request): {
      const { form } = state;
      const { id } = action.payload;

      const newForm = { ...form };
      if (newForm.data && newForm.data.id === id) {
        newForm.error = undefined;
        newForm.loading = true;
      }

      return {
        ...state,
        form: newForm,
        loading: true,
      };
    }

    case getType(news.deleteNew.success): {
      const { form } = state;
      const { id } = action.payload;

      const n = state.news;

      delete (n || {})[id];

      let newForm = { ...form };
      if (newForm.data && newForm.data.id === id) {
        newForm = { loading: false, redirectReady: false };
      }

      return {
        ...state,
        form: newForm,
        news: n,
        error: undefined,
        loading: false,
      };
    }

    case getType(news.deleteNew.failure): {
      const { form } = state;
      const { error, id } = action.payload;

      const newForm = { ...form };
      if (newForm.data && newForm.data.id === id) {
        newForm.error = error;
        newForm.loading = false;
      }

      return {
        ...state,
        form: newForm,
        error,
        loading: false,
      };
    }

    case getType(news.publishNew.request): {
      const { form } = state;
      const { id } = action.payload;

      const newForm = { ...form };
      if (newForm.data && newForm.data.id === id) {
        newForm.error = undefined;
        newForm.loading = true;
      }

      return {
        ...state,
        form: newForm,
        loading: true,
      };
    }

    case getType(news.publishNew.success): {
      const { form } = state;
      const { id } = action.payload;

      const newForm = { ...form };
      if (newForm.data && newForm.data.id === id) {
        newForm.data.published = true;
        newForm.loading = false;
      }

      return {
        ...state,
        form: newForm,
        news: {
          ...state.news,
          [id]: {
            ...((state.news || {})[id] || {}),
            published: true,
          },
        },
        error: undefined,
        loading: false,
      };
    }

    case getType(news.publishNew.failure): {
      const { form } = state;
      const { error, id } = action.payload;

      const newForm = { ...form };
      if (newForm.data && newForm.data.id === id) {
        newForm.error = error;
        newForm.loading = false;
      }

      return {
        ...state,
        form: newForm,
        error,
        loading: false,
      };
    }

    case getType(news.activateNew.request): {
      const { form } = state;
      const { id } = action.payload;

      const newForm = { ...form };
      if (newForm.data && newForm.data.id === id) {
        newForm.error = undefined;
        newForm.loading = true;
      }

      return {
        ...state,
        form: newForm,
        loading: true,
      };
    }

    case getType(news.activateNew.success): {
      const { form } = state;
      const { id } = action.payload;

      const newForm = { ...form };
      if (newForm.data && newForm.data.id === id) {
        newForm.data.active = true;
        newForm.loading = false;
      }

      return {
        ...state,
        form: newForm,
        news: {
          ...state.news,
          [id]: {
            ...((state.news || {})[id] || {}),
            active: true,
          },
        },
        error: undefined,
        loading: false,
      };
    }

    case getType(news.activateNew.failure): {
      const { form } = state;
      const { id, error } = action.payload;

      const newForm = { ...form };
      if (newForm.data && newForm.data.id === id) {
        newForm.error = error;
        newForm.loading = false;
      }

      return {
        ...state,
        form: newForm,
        error,
        loading: false,
      };
    }

    case getType(news.deactivateNew.request): {
      const { form } = state;
      const { id } = action.payload;

      const newForm = { ...form };
      if (newForm.data && newForm.data.id === id) {
        newForm.error = undefined;
        newForm.loading = true;
      }

      return {
        ...state,
        form: newForm,
        loading: true,
      };
    }

    case getType(news.deactivateNew.success): {
      const { form } = state;
      const { id } = action.payload;

      const newForm = { ...form };
      if (newForm.data && newForm.data.id === id) {
        newForm.data.active = false;
        newForm.loading = false;
      }

      return {
        ...state,
        form: newForm,
        news: {
          ...state.news,
          [id]: {
            ...((state.news || {})[id] || {}),
            active: false,
          },
        },
        error: undefined,
        loading: false,
      };
    }

    case getType(news.deactivateNew.failure): {
      const { form } = state;
      const { id, error } = action.payload;

      const newForm = { ...form };
      if (newForm.data && newForm.data.id === id) {
        newForm.error = error;
        newForm.loading = false;
      }

      return {
        ...state,
        form: newForm,
        error,
        loading: false,
      };
    }

    default:
      return { ...state };
  }
};
