import { createAction, createAsyncAction } from 'typesafe-actions';

import Media, { Medias } from 'models/Media';
import { Languages, MediaTypes } from 'models/data';
import { IFailed } from './types';

enum Types {
  FETCH_MEDIA = '@@media/FETCH_MEDIA',
  FETCH_MEDIA_FAILED = '@@media/FETCH_MEDIA_FAILED',
  FETCH_MEDIA_SUCCEEDED = '@@media/FETCH_MEDIA_SUCCEEDED',

  FETCH_MEDIA_ENTRY = '@@media/FETCH_MEDIA_ENTRY',
  FETCH_MEDIA_ENTRY_FAILED = '@@media/FETCH_MEDIA_ENTRY_FAILED',
  FETCH_MEDIA_ENTRY_SUCCEEDED = '@@media/FETCH_MEDIA_ENTRY_SUCCEEDED',

  CREATE_MEDIA = '@@media/CREATE_MEDIA',
  CREATE_MEDIA_FAILED = '@@media/CREATE_MEDIA_FAILED',
  CREATE_MEDIA_SUCCEEDED = '@@media/CREATE_MEDIA_SUCCEEDED',

  UPDATE_MEDIA = '@@media/UPDATE_MEDIA',
  UPDATE_MEDIA_FAILED = '@@media/UPDATE_MEDIA_FAILED',
  UPDATE_MEDIA_SUCCEEDED = '@@media/UPDATE_MEDIA_SUCCEEDED',

  ACTIVATE_MEDIA = '@@media/ACTIVATE_MEDIA',
  ACTIVATE_MEDIA_FAILED = '@@media/ACTIVATE_MEDIA_FAILED',
  ACTIVATE_MEDIA_SUCCEEDED = '@@media/ACTIVATE_MEDIA_SUCCEEDED',

  DEACTIVATE_MEDIA = '@@media/DEACTIVATE_MEDIA',
  DEACTIVATE_MEDIA_FAILED = '@@media/DEACTIVATE_MEDIA_FAILED',
  DEACTIVATE_MEDIA_SUCCEEDED = '@@media/DEACTIVATE_MEDIA_SUCCEEDED',

  DELETE_MEDIA = '@@media/DELETE_MEDIA',
  DELETE_MEDIA_FAILED = '@@media/DELETE_MEDIA_FAILED',
  DELETE_MEDIA_SUCCEEDED = '@@media/DELETE_MEDIA_SUCCEEDED',

  RESET_FORM = '@@media/RESET_FORM',
}

export interface ICreateMedia {
  language: keyof typeof Languages;
  title: string;
  description: string | null;
  mediaType: keyof typeof MediaTypes;
  mediaURL: string;
}

export interface IUpdateMedia extends ICreateMedia {
  id: string;
}

interface IAction {
  id: string;
}

export const fetchMedia = createAsyncAction(
  Types.FETCH_MEDIA,
  Types.FETCH_MEDIA_SUCCEEDED,
  Types.FETCH_MEDIA_FAILED
)<void, Medias, IFailed>();

export const fetchMediaEntry = createAsyncAction(
  Types.FETCH_MEDIA_ENTRY,
  Types.FETCH_MEDIA_ENTRY_SUCCEEDED,
  Types.FETCH_MEDIA_ENTRY_FAILED
)<string, Media, IFailed>();

export const createMedia = createAsyncAction(
  Types.CREATE_MEDIA,
  Types.CREATE_MEDIA_SUCCEEDED,
  Types.CREATE_MEDIA_FAILED
)<ICreateMedia, Media, IFailed>();

export const updateMedia = createAsyncAction(
  Types.UPDATE_MEDIA,
  Types.UPDATE_MEDIA_SUCCEEDED,
  Types.UPDATE_MEDIA_FAILED
)<IUpdateMedia, Media, IFailed>();

export const activateMedia = createAsyncAction(
  Types.ACTIVATE_MEDIA,
  Types.ACTIVATE_MEDIA_SUCCEEDED,
  Types.ACTIVATE_MEDIA_FAILED
)<IAction, IAction, IFailed>();

export const deactivateMedia = createAsyncAction(
  Types.DEACTIVATE_MEDIA,
  Types.DEACTIVATE_MEDIA_SUCCEEDED,
  Types.DEACTIVATE_MEDIA_FAILED
)<IAction, IAction, IFailed>();

export const deleteMedia = createAsyncAction(
  Types.DELETE_MEDIA,
  Types.DELETE_MEDIA_SUCCEEDED,
  Types.DELETE_MEDIA_FAILED
)<IAction, IAction, IFailed>();

export const resetMediaForm = createAction(
  Types.RESET_FORM,
  (action) => () => action()
)();
