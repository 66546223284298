/* eslint-disable prettier/prettier */
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { ActionType } from 'typesafe-actions';

import appReducer, { IAppState } from 'reducers/index';
import sagas from 'sagas/index';

const rootReducer = (state: any, action: ActionType<any>): IAppState => {
  return appReducer(state, action);
};

const sagaMiddleware = createSagaMiddleware();

const middleware =
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools(
      applyMiddleware(sagaMiddleware),
      applyMiddleware(createLogger())
    )
    : applyMiddleware(sagaMiddleware);

const store = createStore(rootReducer, middleware);

sagaMiddleware.run(sagas);

export default store;
