import Media from 'models/Media';
import { Languages } from 'models/data';
import axios from './axios';

export async function fetchSiteMedia(
  language: keyof typeof Languages
): Promise<Media[]> {
  const { data } = await axios.get(`/media/site/${language}`);

  return data;
}

export default {};
