import { ActionType, getType } from 'typesafe-actions';

import * as siteMedia from 'actions/siteMedia';
import Media from 'models/Media';
import FetchError from 'models/FetchError';

type SiteMediaAction = ActionType<typeof siteMedia>;

export interface ISiteMediaState {
  readonly media?: Media[];
  readonly error?: FetchError;
  readonly loading: boolean;
}

const initialState: ISiteMediaState = {
  loading: false,
};

export default (
  state: ISiteMediaState = initialState,
  action: SiteMediaAction
): ISiteMediaState => {
  switch (action.type) {
    case getType(siteMedia.fetchSiteMedia.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(siteMedia.fetchSiteMedia.success): {
      return {
        ...state,
        media: action.payload,
        loading: false,
      };
    }

    case getType(siteMedia.fetchSiteMedia.failure): {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    default:
      return { ...state };
  }
};
