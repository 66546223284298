import CalculatorData from 'models/CalculatorData';
import axios from './axios';

export async function fetchCalculatorData(): Promise<CalculatorData> {
  const { data } = await axios.get('/calculator');

  return data;
}

export async function updateCalculatorData(
  data: CalculatorData
): Promise<CalculatorData> {
  const { data: calcdata } = await axios.post('/calculator', data);

  return calcdata;
}

export default {};
