import { ActionType, getType } from 'typesafe-actions';

import * as calculator from 'actions/calculator';
import CalculatorData from 'models/CalculatorData';
import FetchError from 'models/FetchError';

type CalculatorAction = ActionType<typeof calculator>;

export interface ICalculatorState {
  readonly data?: CalculatorData;
  readonly error?: FetchError;
  readonly redirectReady: boolean;
  readonly loading: boolean;
}

const initialState: ICalculatorState = {
  redirectReady: false,
  loading: false,
};

export default (
  state: ICalculatorState = initialState,
  action: CalculatorAction
): ICalculatorState => {
  switch (action.type) {
    case getType(calculator.fetchCalculatorData.request):
      return {
        ...state,
        data: undefined,
        error: undefined,
        loading: true,
      };

    case getType(calculator.fetchCalculatorData.success): {
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    }

    case getType(calculator.fetchCalculatorData.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(calculator.updateCalculatorData.request):
      return {
        ...state,
        data: undefined,
        error: undefined,
        redirectReady: false,
        loading: true,
      };

    case getType(calculator.updateCalculatorData.success):
      return {
        ...state,
        data: action.payload,
        error: undefined,
        redirectReady: true,
        loading: false,
      };

    case getType(calculator.updateCalculatorData.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        redirectReady: false,
        loading: false,
      };
    }

    default:
      return { ...state };
  }
};
