import { createAsyncAction } from 'typesafe-actions';

import { IFailed, IFailedFeedback } from './types';

enum Types {
  FETCH_SITE_PRICECOUNTRY = '@@site/FETCH_SITE_PRICECOUNTRY',
  FETCH_SITE_PRICECOUNTRY_FAILED = '@@site/FETCH_SITE_PRICECOUNTRY_FAILED',
  FETCH_SITE_PRICECOUNTRY_SUCCEEDED = '@@site/FETCH_SITE_PRICECOUNTRY_SUCCEEDED',

  SUBMIT_DEMOREQUEST = '@@site/SUBMIT_DEMOREQUEST',
  SUBMIT_DEMOREQUEST_FAILED = '@@site/SUBMIT_DEMOREQUEST_FAILED',
  SUBMIT_DEMOREQUEST_SUCCEEDED = '@@site/SUBMIT_DEMOREQUEST_SUCCEEDED',

  REQUEST_DEMO_SUCCESS = '@site/REQUEST_DEMO_SUCCESS',
  REQUEST_DEMO_ERROR = '@site/REQUEST_DEMO_ERROR',
}

export interface ISubmitDemo {
  name: string;
  email: string;
  phone: string;
  country: string;
  company: string;
  jobtitle: string;
  industry: string;
  subsector?: string;
  usecase: string;
  comments?: string;
  intl: any;
}

export const fetchSitePricingCountry = createAsyncAction(
  Types.FETCH_SITE_PRICECOUNTRY,
  Types.FETCH_SITE_PRICECOUNTRY_SUCCEEDED,
  Types.FETCH_SITE_PRICECOUNTRY_FAILED
)<void, string, IFailed>();

export const submitDemoRequest = createAsyncAction(
  Types.SUBMIT_DEMOREQUEST,
  Types.SUBMIT_DEMOREQUEST_SUCCEEDED,
  Types.SUBMIT_DEMOREQUEST_FAILED
)<ISubmitDemo, { intl: any }, IFailedFeedback>();
