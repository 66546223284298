import AccountInfo from 'models/Account';
import { apiAxios } from './axios';

export async function fetchAccount(): Promise<AccountInfo> {
  /**
   * Pragma header so that IE redirects after logout
   * (otherwise will respond from cache, opening dashboard without an active login)
   */
  const { data } = await apiAxios.get('/account', {
    headers: { Pragma: 'no-cache' },
  });

  return data;
}

export const logout = async () => apiAxios.post('/sessions/logout');
