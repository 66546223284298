import { createAsyncAction } from 'typesafe-actions';

import { IFailedFeedback } from './types';

enum Types {
  SUBSCRIBE_NEWSLETTER = '@@subscrive_newsletter/SUBSCRIBE_NEWSLETTER',
  SUBSCRIBE_NEWSLETTER_FAILED = '@@subscrive_newsletter/SUBSCRIBE_NEWSLETTER_FAILED',
  SUBSCRIBE_NEWSLETTER_SUCCEEDED = '@@subscrive_newsletter/SUBSCRIBE_NEWSLETTER_SUCCEEDED',
}

export interface ISubscribeNewsletter {
  email: string;
  intl: any;
  feedback: boolean;
}

export interface ISubscribeNewsletterSuccess {
  intl: any;
}

export const subscribeNewsletter = createAsyncAction(
  Types.SUBSCRIBE_NEWSLETTER,
  Types.SUBSCRIBE_NEWSLETTER_SUCCEEDED,
  Types.SUBSCRIBE_NEWSLETTER_FAILED
)<ISubscribeNewsletter, ISubscribeNewsletterSuccess, IFailedFeedback>();
