import { call, put } from 'redux-saga/effects';

import { ActionType } from 'typesafe-actions';

import * as types from 'actions/siteDocuments';
import { fetchSiteDocuments } from 'api/siteDocuments';
import { treatFetchError } from 'api/utils';

export function* fetchSiteDocumentsSaga(
  action: ActionType<typeof types.fetchSiteDocuments.request>
): any {
  const { lang } = action.payload;

  try {
    const documents = yield call(fetchSiteDocuments, lang);

    yield put(types.fetchSiteDocuments.success(documents));
  } catch (error: any) {
    yield put(
      types.fetchSiteDocuments.failure({ error: treatFetchError(error) })
    );
  }
}

export default {};
