import { ActionType, getType } from 'typesafe-actions';

import * as media from 'actions/media';
import Media, { Medias } from 'models/Media';
import FetchError from 'models/FetchError';

type MediaAction = ActionType<typeof media>;

interface IForm {
  data?: Media;
  error?: FetchError;
  redirectReady: boolean;
  loading: boolean;
}

export interface IMediaState {
  readonly form: IForm;
  readonly media?: Medias;
  readonly error?: FetchError;
  readonly loading: boolean;
}

const initialState: IMediaState = {
  loading: false,
  form: {
    redirectReady: false,
    loading: false,
  },
};

export default (
  state: IMediaState = initialState,
  action: MediaAction
): IMediaState => {
  switch (action.type) {
    case getType(media.fetchMedia.request):
      return {
        ...state,
        loading: true,
      };

    case getType(media.fetchMedia.success): {
      return {
        ...state,
        media: action.payload,
        error: undefined,
        loading: false,
      };
    }

    case getType(media.fetchMedia.failure): {
      const { error } = action.payload;

      return {
        ...state,
        media: undefined,
        error,
        loading: false,
      };
    }

    case getType(media.fetchMediaEntry.request):
      return {
        ...state,
        form: {
          ...state.form,
          data: undefined,
          error: undefined,
          loading: true,
        },
      };

    case getType(media.fetchMediaEntry.success): {
      return {
        ...state,
        form: {
          ...state.form,
          data: action.payload,
          loading: false,
        },
        error: undefined,
        loading: false,
      };
    }

    case getType(media.fetchMediaEntry.failure): {
      const { error } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          data: undefined,
          loading: false,
          error,
        },
        error,
        loading: false,
      };
    }

    case getType(media.createMedia.request):
      return {
        ...state,
        form: {
          ...state.form,
          redirectReady: false,
          data: undefined,
          error: undefined,
          loading: true,
        },
        loading: true,
      };

    case getType(media.createMedia.success): {
      const { id } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          redirectReady: true,
          data: {
            ...state.form.data,
            ...action.payload,
          },
          loading: false,
        },
        media: {
          ...state.media,
          [id]: action.payload,
        },
        error: undefined,
        loading: false,
      };
    }

    case getType(media.createMedia.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        form: {
          ...state.form,
          redirectReady: false,
          loading: false,
          error,
        },
        loading: false,
      };
    }

    case getType(media.updateMedia.request):
      return {
        ...state,
        form: {
          ...state.form,
          redirectReady: false,
          error: undefined,
          loading: true,
        },
        loading: true,
      };

    case getType(media.updateMedia.success): {
      const { id } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          redirectReady: true,
          data: {
            ...state.form.data,
            ...action.payload,
          },
          loading: false,
        },
        media: {
          ...state.media,
          [id]: action.payload,
        },
        error: undefined,
        loading: false,
      };
    }

    case getType(media.updateMedia.failure): {
      const { error } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          redirectReady: false,
          error,
          loading: false,
        },
        error,
        loading: false,
      };
    }

    case getType(media.activateMedia.request): {
      return {
        ...state,
        loading: true,
      };
    }

    case getType(media.activateMedia.success): {
      const { id } = action.payload;

      return {
        ...state,
        media: {
          ...state.media,
          [id]: {
            ...((state.media || {})[id] || {}),
            active: true,
          },
        },
        error: undefined,
        loading: false,
      };
    }

    case getType(media.activateMedia.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(media.deactivateMedia.request): {
      return {
        ...state,
        loading: true,
      };
    }

    case getType(media.deactivateMedia.success): {
      const { id } = action.payload;

      return {
        ...state,
        media: {
          ...state.media,
          [id]: {
            ...((state.media || {})[id] || {}),
            active: false,
          },
        },
        error: undefined,
        loading: false,
      };
    }

    case getType(media.deactivateMedia.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(media.deleteMedia.request): {
      return {
        ...state,
        loading: true,
      };
    }

    case getType(media.deleteMedia.success): {
      const { id } = action.payload;

      const n = state.media;

      delete (n || {})[id];

      return {
        ...state,
        media: n,
        error: undefined,
        loading: false,
      };
    }

    case getType(media.deleteMedia.failure): {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case getType(media.resetMediaForm):
      return {
        ...state,
        form: {
          redirectReady: true,
          loading: false,
        },
      };

    default:
      return { ...state };
  }
};
