import { ActionType } from 'typesafe-actions';
import { call, put } from 'redux-saga/effects';

import * as types from 'actions/news';
import {
  fetchNews,
  fetchNew,
  createNew,
  updateNew,
  deleteNew,
  publishNew,
  activateNew,
  deactivateNew,
} from 'api/news';
import { treatFetchError } from 'api/utils';
import New from 'models/New';

export function* fetchNewsSaga(): any {
  try {
    const news = yield call(fetchNews);

    yield put(types.fetchNews.success(news));
  } catch (error: any) {
    yield put(types.fetchNews.failure({ error: treatFetchError(error) }));
  }
}

export function* fetchNewSaga(
  action: ActionType<typeof types.fetchNew.request>
): any {
  try {
    const news = yield call(fetchNew, action.payload);

    yield put(types.fetchNew.success(news));
  } catch (error: any) {
    yield put(types.fetchNew.failure({ error: treatFetchError(error) }));
  }
}

export function* createNewSaga(
  action: ActionType<typeof types.createNew.request>
) {
  const { language, title, content, categories, permalink, featuredImageId } =
    action.payload;

  try {
    const data: New = yield call(
      createNew,
      language,
      title,
      content,
      categories,
      permalink,
      featuredImageId
    );

    yield put(types.createNew.success(data));
  } catch (error: any) {
    yield put(types.createNew.failure({ error: treatFetchError(error) }));
  }
}

export function* updateNewSaga(
  action: ActionType<typeof types.updateNew.request>
) {
  const {
    id,
    language,
    title,
    content,
    categories,
    permalink,
    featuredImageId,
  } = action.payload;

  try {
    const data: New = yield call(
      updateNew,
      id,
      language,
      title,
      content,
      categories,
      permalink,
      featuredImageId
    );

    yield put(types.updateNew.success(data));
  } catch (error: any) {
    yield put(types.updateNew.failure({ error: treatFetchError(error) }));
  }
}

export function* deleteNewSaga(
  action: ActionType<typeof types.deleteNew.request>
) {
  const { id } = action.payload;

  try {
    yield call(deleteNew, id);

    yield put(types.deleteNew.success({ id }));
  } catch (error: any) {
    yield put(types.deleteNew.failure({ error: treatFetchError(error), id }));
  }
}

export function* publishNewSaga(
  action: ActionType<typeof types.publishNew.request>
) {
  const { id } = action.payload;

  try {
    yield call(publishNew, id);

    yield put(types.publishNew.success({ id }));
  } catch (error: any) {
    yield put(types.publishNew.failure({ error: treatFetchError(error), id }));
  }
}

export function* activateNewSaga(
  action: ActionType<typeof types.activateNew.request>
) {
  const { id } = action.payload;

  try {
    yield call(activateNew, id);

    yield put(types.activateNew.success({ id }));
  } catch (error: any) {
    yield put(types.activateNew.failure({ error: treatFetchError(error), id }));
  }
}

export function* deactivateNewSaga(
  action: ActionType<typeof types.deactivateNew.request>
) {
  const { id } = action.payload;

  try {
    yield call(deactivateNew, id);

    yield put(types.deactivateNew.success({ id }));
  } catch (error: any) {
    yield put(
      types.deactivateNew.failure({ error: treatFetchError(error), id })
    );
  }
}
