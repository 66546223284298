import New from 'models/New';
import { Languages } from 'models/data';
import axios from './axios';

export async function fetchSiteNews(
  language: keyof typeof Languages,
  category?: string,
  search?: string
): Promise<New[]> {
  let url = `/news/site/${language}`;

  if (category) {
    url = `/news/site/${language}?category=${encodeURIComponent(category)}`;
  }
  if (search) {
    url = `/news/site/${language}?search=${encodeURIComponent(search)}`;
  }

  const { data } = await axios.get(url);

  return data;
}

export async function fetchSiteNewsById(id: string): Promise<New> {
  const { data } = await axios.get(`/news/site/byid/${id}`);

  return data;
}

export async function fetchSiteNewsByPermalink(
  permalink: string
): Promise<New> {
  const { data } = await axios.get(`/news/site/bypermalink/${permalink}`);

  return data;
}

export async function fetchSiteNewsCategories(
  language: keyof typeof Languages
): Promise<string[]> {
  const { data } = await axios.get(`/news/site/categories/${language}`);

  return data;
}

export default {};
