import { call, put } from 'redux-saga/effects';

import { ActionType } from 'typesafe-actions';

import * as types from 'actions/site';
import { fetchSitePricingCountry, submitDemoRequest } from 'api/site';
import { treatFetchError } from 'api/utils';

export function* fetchSitePricingCountrySaga(): any {
  try {
    const media = yield call(fetchSitePricingCountry);

    yield put(types.fetchSitePricingCountry.success(media));
  } catch (error: any) {
    yield put(
      types.fetchSitePricingCountry.failure({ error: treatFetchError(error) })
    );
  }
}

export function* submitDemoRequestSaga(
  action: ActionType<typeof types.submitDemoRequest.request>
) {
  const {
    name,
    email,
    phone,
    country,
    company,
    jobtitle,
    industry,
    subsector,
    usecase,
    comments,

    intl,
  } = action.payload;

  try {
    yield call(
      submitDemoRequest,
      name,
      email,
      phone,
      country,
      company,
      jobtitle,
      industry,
      subsector,
      usecase,
      comments
    );

    yield put(types.submitDemoRequest.success({ intl }));
  } catch (error: any) {
    yield put(
      types.submitDemoRequest.failure({ error: treatFetchError(error), intl })
    );
  }
}
