import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ThemeOptions } from '@mui/material/styles/createTheme';
import { createBrowserHistory } from 'history';
import React, { Component, lazy, Suspense } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router';

import ActionFeedback from './utils/ActionFeedback';
import PickerLoading from './utils/PickerLoading';
import Intl from './utils/IntlProvider';
import LocalLoading from './utils/LocalLoading';

const BackOffice = lazy(() => import('./backoffice/Index'));
const Site = lazy(() => import('./Site'));

const theme = createTheme({
  typography: {
    fontFamily: ['Varela'].join(','),
  },
  overrides: {
    MUIDataTable: {
      paper: {
        boxShadow: 'none',
        flex: 1,
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 700,
      },
      root: {
        padding: 8,
      },
    },
  },
} as ThemeOptions);

interface IState {
  history: any;
  loading: boolean;
}

class App extends Component<{}, IState> {
  constructor(props: {}) {
    super(props);

    this.state = {
      history: createBrowserHistory(),
      loading: true,
    };

    this.handleLoad = this.handleLoad.bind(this);
  }

  public componentDidMount() {
    window.addEventListener('load', this.handleLoad);
  }

  public componentWillUnmount() {
    window.removeEventListener('load', this.handleLoad);
  }

  public handleLoad() {
    this.setState({ loading: false });
  }

  public render() {
    const { loading, history } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline>
          {loading ? (
            <div className="loading">
              <img src="/loading.gif" alt="..." />
            </div>
          ) : (
            <Suspense fallback={<LocalLoading loading />}>
              <Router history={history}>
                <Switch>
                  <Route
                    path="/backoffice"
                    exact={false}
                    component={() => (
                      <PickerLoading language="en">
                        <Intl lang="en">
                          <BackOffice history={history} />
                        </Intl>
                      </PickerLoading>
                    )}
                  />
                  <Route
                    path="/:lang/:page"
                    exact={false}
                    component={(props: any) => (
                      <PickerLoading language={props.match.params.lang}>
                        <Site
                          history={history}
                          match={props.match}
                          location={props.location}
                        />
                      </PickerLoading>
                    )}
                  />
                  <Route
                    path="/:lang"
                    exact={false}
                    component={(props: any) => (
                      <PickerLoading language={props.match.params.lang}>
                        <Site
                          history={history}
                          match={props.match}
                          location={props.location}
                        />
                      </PickerLoading>
                    )}
                  />
                  <Redirect to="/en" />
                </Switch>
              </Router>
            </Suspense>
          )}
          <ActionFeedback />
        </CssBaseline>
      </ThemeProvider>
    );
  }
}

export default App;
