import { combineReducers } from 'redux';
import app, { IAppState as IAppStateReducer } from './app';
import actionFeedback, { IActionFeedbackState } from './actionFeedback';
import authentication, { IAuthenticationState } from './authentication';
import calculator, { ICalculatorState } from './calculator';
import documents, { IDocumentsState } from './documents';
import library, { ILibraryState } from './library';
import media, { IMediaState } from './media';
import news, { INewsState } from './news';
import newsletter, { INewsletterState } from './newsletter';
import site, { ISiteState } from './site';
import siteDocuments, { ISiteDocumentState } from './siteDocuments';
import siteMedia, { ISiteMediaState } from './siteMedia';
import siteNews, { ISiteNewsState } from './siteNews';
import siteSubscriptions, {
  ISiteSubscriptionsState,
} from './siteSubscriptions';

export enum RequestStatus {
  inProgress,
  failed,
  succeeded,
}

export interface IAppState {
  app: IAppStateReducer;
  authentication: IAuthenticationState;
  actionFeedback: IActionFeedbackState;
  calculator: ICalculatorState;
  documents: IDocumentsState;
  library: ILibraryState;
  media: IMediaState;
  news: INewsState;
  newsletter: INewsletterState;
  site: ISiteState;
  siteDocuments: ISiteDocumentState;
  siteMedia: ISiteMediaState;
  siteNews: ISiteNewsState;
  siteSubscriptions: ISiteSubscriptionsState;
}

export default combineReducers({
  app,
  authentication,
  actionFeedback,
  calculator,
  documents,
  library,
  media,
  news,
  newsletter,
  site,
  siteDocuments,
  siteMedia,
  siteNews,
  siteSubscriptions,
});
