import { createAction, createAsyncAction } from 'typesafe-actions';

import { Languages } from 'models/data';
import New, { News } from 'models/New';
import { IFailed } from './types';

enum Types {
  FETCH_NEWS = '@@news/FETCH_NEWS',
  FETCH_NEWS_FAILED = '@@news/FETCH_NEWS_FAILED',
  FETCH_NEWS_SUCCEEDED = '@@news/FETCH_NEWS_SUCCEEDED',

  FETCH_NEW = '@@news/FETCH_NEW',
  FETCH_NEW_FAILED = '@@news/FETCH_NEW_FAILED',
  FETCH_NEW_SUCCEEDED = '@@news/FETCH_NEW_SUCCEEDED',

  CREATE_NEW = '@@news/CREATE_NEW',
  CREATE_NEW_FAILED = '@@news/CREATE_NEW_FAILED',
  CREATE_NEW_SUCCEEDED = '@@news/CREATE_NEW_SUCCEEDED',

  UPDATE_NEW = '@@news/UPDATE_NEW',
  UPDATE_NEW_FAILED = '@@news/UPDATE_NEW_FAILED',
  UPDATE_NEW_SUCCEEDED = '@@news/UPDATE_NEW_SUCCEEDED',

  DELETE_NEW = '@@news/DELETE_NEW',
  DELETE_NEW_FAILED = '@@news/DELETE_NEW_FAILED',
  DELETE_NEW_SUCCEEDED = '@@news/DELETE_NEW_SUCCEEDED',

  PUBLISH_NEW = '@@news/PUBLISH_NEW',
  PUBLISH_NEW_FAILED = '@@news/PUBLISH_NEW_FAILED',
  PUBLISH_NEW_SUCCEEDED = '@@news/PUBLISH_NEW_SUCCEEDED',

  ACTIVATE_NEW = '@@news/ACTIVATE_NEW',
  ACTIVATE_NEW_FAILED = '@@news/ACTIVATE_NEW_FAILED',
  ACTIVATE_NEW_SUCCEEDED = '@@news/ACTIVATE_NEW_SUCCEEDED',

  DEACTIVATE_NEW = '@@news/DEACTIVATE_NEW',
  DEACTIVATE_NEW_FAILED = '@@news/DEACTIVATE_NEW_FAILED',
  DEACTIVATE_NEW_SUCCEEDED = '@@news/DEACTIVATE_NEW_SUCCEEDED',

  RESET_NEW_FORM = '@news/RESET_NEW_FORM',
}

export interface ICreateNew {
  language: keyof typeof Languages;
  title: string;
  content: string;
  categories: string[];
  permalink: string | null;
  featuredImageId: string | null;
}

export interface IUpdateNew extends ICreateNew {
  id: string;
}

interface IAction {
  id: string;
}

interface IActionError extends IFailed {
  id: string;
}

export const fetchNews = createAsyncAction(
  Types.FETCH_NEWS,
  Types.FETCH_NEWS_SUCCEEDED,
  Types.FETCH_NEWS_FAILED
)<void, News, IFailed>();

export const fetchNew = createAsyncAction(
  Types.FETCH_NEW,
  Types.FETCH_NEW_SUCCEEDED,
  Types.FETCH_NEW_FAILED
)<string, New, IFailed>();

export const createNew = createAsyncAction(
  Types.CREATE_NEW,
  Types.CREATE_NEW_SUCCEEDED,
  Types.CREATE_NEW_FAILED
)<ICreateNew, New, IFailed>();

export const updateNew = createAsyncAction(
  Types.UPDATE_NEW,
  Types.UPDATE_NEW_SUCCEEDED,
  Types.UPDATE_NEW_FAILED
)<IUpdateNew, New, IFailed>();

export const deleteNew = createAsyncAction(
  Types.DELETE_NEW,
  Types.DELETE_NEW_SUCCEEDED,
  Types.DELETE_NEW_FAILED
)<IAction, IAction, IActionError>();

export const publishNew = createAsyncAction(
  Types.PUBLISH_NEW,
  Types.PUBLISH_NEW_SUCCEEDED,
  Types.PUBLISH_NEW_FAILED
)<IAction, IAction, IActionError>();

export const activateNew = createAsyncAction(
  Types.ACTIVATE_NEW,
  Types.ACTIVATE_NEW_SUCCEEDED,
  Types.ACTIVATE_NEW_FAILED
)<IAction, IAction, IActionError>();

export const deactivateNew = createAsyncAction(
  Types.DEACTIVATE_NEW,
  Types.DEACTIVATE_NEW_SUCCEEDED,
  Types.DEACTIVATE_NEW_FAILED
)<IAction, IAction, IActionError>();

export const resetNewsForm = createAction(
  Types.RESET_NEW_FORM,
  (action) => () => action()
)();
