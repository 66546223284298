import { createAction } from 'typesafe-actions';

enum Types {
  CLOSE = '@@actionFeedback/CLOSE',
}

export const closeNotification = createAction(
  Types.CLOSE,
  (uuid: string) => uuid
)();

export default {};
