import { ActionType, getType } from 'typesafe-actions';

import * as siteNews from 'actions/siteNews';
import New from 'models/New';
import FetchError from 'models/FetchError';

type SiteNewsAction = ActionType<typeof siteNews>;

interface ICategoriesState {
  error?: FetchError;
  list?: string[];
  loading: boolean;
}
export interface ISiteNewsState {
  readonly categories: ICategoriesState;
  readonly pageNew?: New;
  readonly news?: New[];
  readonly error?: FetchError;
  readonly loading: boolean;
}

const initialState: ISiteNewsState = {
  categories: {
    loading: false,
  },
  loading: false,
};

export default (
  state: ISiteNewsState = initialState,
  action: SiteNewsAction
): ISiteNewsState => {
  switch (action.type) {
    case getType(siteNews.fetchSiteNews.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(siteNews.fetchSiteNews.success): {
      return {
        ...state,
        news: action.payload,
        loading: false,
      };
    }

    case getType(siteNews.fetchSiteNews.failure): {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case getType(siteNews.fetchSiteNewsById.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(siteNews.fetchSiteNewsById.success): {
      return {
        ...state,
        pageNew: action.payload,
        loading: false,
      };
    }

    case getType(siteNews.fetchSiteNewsById.failure): {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case getType(siteNews.fetchSiteNewsByPermalink.request):
      return {
        ...state,
        error: undefined,
        loading: true,
      };

    case getType(siteNews.fetchSiteNewsByPermalink.success): {
      return {
        ...state,
        pageNew: action.payload,
        loading: false,
      };
    }

    case getType(siteNews.fetchSiteNewsByPermalink.failure): {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case getType(siteNews.fetchSiteNewsCategories.request):
      return {
        ...state,
        categories: {
          error: undefined,
          loading: true,
        },
      };

    case getType(siteNews.fetchSiteNewsCategories.success): {
      return {
        ...state,
        categories: {
          list: action.payload,
          loading: false,
        },
      };
    }

    case getType(siteNews.fetchSiteNewsCategories.failure): {
      const { error } = action.payload;

      return {
        ...state,
        categories: {
          loading: false,
          error,
        },
      };
    }

    default:
      return { ...state };
  }
};
